.story-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    .strikediag {
        background: linear-gradient(
            to left top,
            transparent 47.75%,
            currentColor 49.5%,
            currentColor 50.5%,
            transparent 52.25%
        );
    }

    .story-title {
        text-align: center;
        // font-family: fantasy;
        letter-spacing: 3px;
        &:last-child {
            span {
                margin-left: 2rem;
                margin-right: 0;
                padding: 1.5rem 1rem;
            }
        }
        span {
            background-color: #465a55;
            color: #fff;
            border-radius: 100px;
            padding: 1.8rem 1rem;
            font-size: calc(1rem + 1.5vmin);
        }
    }
    .story-content {
        background-color: #e0ddcf;
        padding: 2rem;
        &.then {
            p {
                letter-spacing: 10px;
                margin-bottom: 1rem;
                text-align: center;
                font-size: calc(0.8rem + 1vmin);
            }
            .flames {
                font-size: calc(1rem + 2vmin);
                letter-spacing: 22px;

                .highlighted {
                    background-color: #465a55;
                    color: #fff;
                    border-radius: 100px;
                    padding: 0.8rem 1rem;
                    letter-spacing: 0;
                    margin-right: 8px;
                }
            }
        }

        &.now {
            min-height: 250px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            p {
                letter-spacing: 2px;
                font-size: calc(0.8rem + 1vmin);
            }
            .strike {
                text-decoration: line-through;
            }
        }
    }

    @media only screen and (max-width: 768px) {
        .story-content {
            padding: 0.8rem;
            &.then {
                p {
                    font-size: calc(0.7rem + 1vmin);
                    letter-spacing: 3px;
                }
                .flames{
                    font-size: calc(0.9rem + 1vmin);
                    letter-spacing: 10px;
                    .highlighted{
                        padding: 0.5rem 0.6rem;
                    }
                }
            }
        }
    }
}
