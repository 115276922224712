@import url('https://fonts.googleapis.com/css2?family=Petit+Formal+Script&display=swap');

.App {
  background-color: #fffaf2;
}

::-webkit-scrollbar {
  width: 5px;
  background-color: #465a55; /* or add it to the track */
}

html {
  scroll-behavior: smooth;
  scrollbar-width: 5px;
}

.font-12px {
  font-size: 12px;
}

.primary-color {
  color: #465a55;
}
.sub-head-title {
  font-family: "Petit Formal Script", cursive;
  margin-bottom: 3rem;
  color: #465a55;
  font-size: calc(1rem + 3vmin);
}
#event, #story, #rsvp, #location{
  padding-top: 5rem;
}

@media only screen and (max-width: 768px) {
  .heading-flower {
    width: 50% !important;
  }
}

@media only screen and (max-width: 991px) {
  .sub-head-title {
    font-size: calc(1rem + 3.5vmin);
  }
}
