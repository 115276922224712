.landing-page {
    // min-height: 83vh;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    // margin-bottom: 4rem;
    overflow: hidden;

    .landing-flower {
        position: absolute;
        top: 28%;
        width: 42%;
    }
    .landing-flower.left {
        right: -25%;
    }
    .landing-flower.right {
        left: -28%;
    }

    .banner {
        text-align: center;
        font-size: calc(12px + 6vw);
        font-family: cursive;
        letter-spacing: 6px;
        color: #465a55;
        padding-top: 9rem;
    }

    .nav-wrapper {
        .underline{
            display: flex;
            justify-content: center;
            align-items: center;
            padding-top: 1rem;

            span{
                height: 2px;
                width: 16%;
                background-color: #465a55;
            }
            img{
                width: 4%;
            }
        }
        a {
            text-decoration: none;
            border: 1px solid #465a55;
            padding: 0.5rem 1rem;
            color: #465a55;
            margin-right: 1rem;
            border-radius: 100px;
            font-size: 0.9rem;

            &:last-child{
                margin-right: 0;
            }

            &:hover {
                background-color: #465a55;
                color: #fff;
            }
        }
    }

  

    @media only screen and (max-width:768px) {

        .underline{
            span{
                width: 25% !important;
            }
            img{
                width: 10% !important;
            }
        }

        height: 85vh !important;
        
    }
}
