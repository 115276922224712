.event-wrapper {
    position: relative;
    overflow: hidden;

    .event {
        p {
            // font-family: fantasy;
            text-transform: uppercase;
            letter-spacing: 2px;
            color: #465a55;
            font-weight: 600;
            margin-bottom: 0.5rem;
        }

        .first-event {
            margin-right: 4rem;
        }
        span {
            color: #465a55;
            font-size: 0.9rem;
        }
    }

    .event-right-flower {
        position: absolute;
        right: -2%;
        top: 0%;
        max-width: 15%;
    }

    .live-video-wrapper {
        position: relative;
        margin-top: 4.5rem;
        .live-video {
            .image-wrapper {
                // box-shadow: 1px 4px 13px #cdcdcd;
                margin-bottom: 2rem;

                .live-demo-img {
                    width: 100%;
                    height: 100%;
                }
            }
            p {
                // font-family: fantasy;
                letter-spacing: 2px;
                font-size: 1rem;
                color: #465a55;
                font-weight: 600;
                line-height: 25px;
            }
        }
        .event-right-image {
            position: absolute;
            left: -16%;
            top: -15%;
            filter: opacity(0.3);
            width: 30%;
        }
    }

    @media only screen and (max-width: 768px) {
        .event-right-image {
            width: 50% !important;
            left: -31% !important;
        }
        .live-video {
            p {
                font-size: 0.8rem !important;
            }
        }
        .event-right-flower {
            width: 23% !important;
        }
        .live-video-wrapper {
            margin-top: 1.5rem !important;
        }
    }
}
