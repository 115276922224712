$toggle-indicator-size: 24px; // changing this number will resize the whole toggle
$track-height: $toggle-indicator-size + 6;
$track-width: $toggle-indicator-size * 2.5;
$highContrastModeSupport: solid 2px transparent;
/* 

The following vars come from my theme. 
You'll need to replace with your own color values. 

- "$light"
- "$mid"
- "#000"

*/
// $track-border: $mid;
// $track-background: $light;
// $focus-ring: 0px 0px 0px 2px #000;

// Toggle specific styles
.toggle {
	align-items: center;
	border-radius: 100px;
	display: flex;
	margin-bottom: 16px;

	&:last-of-type {
		margin: 0;
	}
}

// Since we can't style the checkbox directly, we "hide" it so we can draw the toggle.
.toggle__input {
	clip: rect(0 0 0 0);
	clip-path: inset(50%);
	height: 1px;
	overflow: hidden;
	position: absolute;
	white-space: nowrap;
	width: 1px;
	margin-bottom: 2em;

	// This style sets the focus ring. The ":not([disabled])" prevents the focus ring from creating a flash when the element is clicked.
	&:not([disabled]):active + .toggle-track,
	&:not([disabled]):focus + .toggle-track {
		border: 1px solid transparent;
		box-shadow: 0px 0px 0px 2px #465a55;
	}

	&:disabled + .toggle-track {
		cursor: not-allowed;
		opacity: 0.7;
	}
}

.toggle-track {
	background: #fffaf2;
	border: 1px solid #465a55;
	border-radius: 100px;
	cursor: pointer;
	display: flex;
	height: $track-height;
	margin-right: 12px;
	position: relative;
	width: $track-width;
}

.toggle-indicator {
	align-items: center;
	background: #465a55;
	border-radius: $toggle-indicator-size;
	bottom: 2px;
	display: flex;
	height: $toggle-indicator-size;
	justify-content: center;
	left: 2px;
	outline: $highContrastModeSupport;
	position: absolute;
	transition: 0.5s;
	width: $toggle-indicator-size;
}

// The check mark is optional
.checkMark {
	fill: #fff;
	height: $toggle-indicator-size;
	width: $toggle-indicator-size - 4;
	opacity: 0;
	transition: opacity 0.5s ease-in-out;
}

.toggle__input:checked + .toggle-track .toggle-indicator {
	background: #465a55;
	transform: translateX($track-width - $track-height);

	.checkMark {
		opacity: 1;
		transition: opacity 0.5s ease-in-out;
	}
}

.confirm-wrapper {
	

	// h4{
	// font-family: "Petit Formal Script",cursive;
	// margin-bottom: 3rem;
	// color: #465a55;
	// font-size: calc(1rem + 3vmin);
	// }
	label{
		color: #465a55;
		font-size: 0.9rem;
	}
	.form-control {
		background: #fffaf2;
		border: 1px solid #465a55;
		margin-bottom: 2em;
		color: #465a55;
		font-size: 0.9rem;
	}
	.btn-color {
		background-color: #465a55;
		color: #fff;
		font-size: 0.9rem;
	}
	.picture{
		
		img{
			max-width: 29vmax;
		}
	}
}

@media screen and (-ms-high-contrast: active) {
	.toggle-track {
		border-radius: 0;
	}

}


// @media only screen and (max-width:991px) {
// 	.confirm-wrapper{
// 		h4{
// 			font-size: calc(1rem + 3.5vmin);
// 		}
// 	}
// }