.location-wrapper {
    display: flex;
    background-color: #f8f8f5;
    .location-map {
        box-shadow: 2px 2px 3px #cdcdd2;
    }

    .address-content {
        justify-content: space-evenly;
    }
    .location-content {
        h3 {
            font-family: "Rochester", cursive;
            color: #465a55;
            font-size: calc(1rem + 3vmin);
        }
        h5 {
            color: #20191a;
            font-size: calc(1rem + 0.5vmin);
        }
        h6 {
            text-align: center;
            span {
                color: #465a55;
                border: 1px solid #465a55;
                padding: 0.5rem;
                letter-spacing: 2px;
                text-transform: uppercase;
                font-size: 12px;
            }
        }
        p {
            font-size: 0.9rem;
            color: #939090;
        }
        button {
            border: 1px solid #465a55;
            color: #20191a;

            &:focus,
            &:active,
            &.active {
                background-color: #465a55;
                color: #fff;
            }
        }
    }
}
@media only screen and (max-width: 991px) {
    .location-wrapper {
        flex-direction: column-reverse;

        .location-content {
            padding-bottom: 3rem;
            border-bottom: 2px solid #ededed;
        }
        .location-map {
            padding: 0;
        }
    }
}

@media only screen and (max-width: 500px) {
    .location-wrapper {
        .address-content {
            justify-content: space-between;
            padding: 0 4px;
        }
        .location-content {
            h5{
                font-size: 0.9rem;
            }
            p {
                font-size: 0.8rem !important;
            }
            button{
                font-size: 0.8rem;
            }
        }
    }
}
