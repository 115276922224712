@import url("https://fonts.googleapis.com/css?family=Droid+Sans+Mono");
* {
  box-sizing: border-box;
}

#app {
  display: flex;
  position: relative;
  width: 100%;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  background-color: #FBAB7E;
  background-image: linear-gradient(62deg, #FBAB7E 0%, #F7CE68 100%);
}

header {
  display: flex;
  position: relative;
}
header h1 {
  font-family: "Droid Sans Mono", monospace;
  font-weight: lighter;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  color: white;
}

.flipClock {
  display: flex;
  justify-content: space-between;
  min-width: 20vmin;
  font-size: 1.5vmin;
  margin-bottom: 3rem;
}

.flipUnitContainer {
  display: block;
  position: relative;
  width: 20vmin;
  height: 18vmin;
  perspective-origin: 50% 50%;
  perspective: 300px;
  background-color: rgb(57, 57, 57);
  color: #20191a;
  border-radius: 3px;
  box-shadow: 0px 10px 10px -10px rgb(57 57 57);
  margin-left: 5px;
  margin-right: 0.5rem;
  // font-family: fantasy;
}

.upperCard, .lowerCard {
  display: flex;
  position: relative;
  justify-content: center;
  width: 100%;
  height: 50%;
  overflow: hidden;
  /* border: 1px solid whitesmoke; */
  border:1px solid  rgb(57, 57, 57);
}
.upperCard span, .lowerCard span {
  font-size: 5em;
  font-family: "Droid Sans Mono", monospace;
  font-weight: lighter;
  color: #fff;
}

.upperCard {
  align-items: flex-end;
  border-bottom: 1.5px solid rgb(35 35 35);
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
.upperCard span {
  transform: translateY(50%);
}

.lowerCard {
  align-items: flex-start;
  border-top: 0.5px solid  rgb(57, 57, 57);;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}
.lowerCard span {
  transform: translateY(-50%);
}

.flipCard {
  display: flex;
  justify-content: center;
  position: absolute;
  left: 0;
  width: 100%;
  height: 50%;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}
.flipCard span {
  font-family: "Droid Sans Mono", monospace;
  font-size: 5em;
  font-weight: lighter;
  color: #fff;
}
.flipCard.unfold {
  top: 50%;
  align-items: flex-start;
  transform-origin: 50% 0%;
  transform: rotateX(180deg);
  background-color:  rgb(57, 57, 57);;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border: 0.5px solid  rgb(57, 57, 57);;
  border-top: 0.5px solid  rgb(57, 57, 57);;
}
.flipCard.unfold span {
  transform: translateY(-50%);
}
.flipCard.fold {
  top: 0%;
  align-items: flex-end;
  transform-origin: 50% 100%;
  transform: rotateX(0deg);
  background-color:  rgb(57, 57, 57);;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border: 0.5px solid  rgb(57, 57, 57);;
  border-bottom: 0.5px solid  rgb(57, 57, 57);;
}
.flipCard.fold span {
  transform: translateY(50%);

}
.units{
  font-size: 4vmin;
}

.fold {
  -webkit-animation: fold 0.6s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s 1 normal forwards;
          animation: fold 0.6s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s 1 normal forwards;
  transform-style: preserve-3d;
}

.unfold {
  -webkit-animation: unfold 0.6s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s 1 normal forwards;
          animation: unfold 0.6s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s 1 normal forwards;
  transform-style: preserve-3d;
}

@-webkit-keyframes fold {
  0% {
    transform: rotateX(0deg);
  }
  100% {
    transform: rotateX(-180deg);
  }
}

@keyframes fold {
  0% {
    transform: rotateX(0deg);
  }
  100% {
    transform: rotateX(-180deg);
  }
}
@-webkit-keyframes unfold {
  0% {
    transform: rotateX(180deg);
  }
  100% {
    transform: rotateX(0deg);
  }
}
@keyframes unfold {
  0% {
    transform: rotateX(180deg);
  }
  100% {
    transform: rotateX(0deg);
  }
}