.header {
    position: fixed;
    width: 100%;
    z-index: 1000;
    box-shadow: 0 4px 3px #f7f0e5;
    background-color: #fffaf2;
    padding-bottom: 0.2rem;
    

    .navbar-brand{
        img {
            width: 7vmin;
            border-radius: 100px;
        }
    }
    button{
        font-size: calc(0.5rem + 1vmin);

        &:focus, &:focus-visible{
            box-shadow: none;
            
        }
    }
    

    .navbar-nav{
        margin-left: auto;
    }
    // ul {
    //     list-style-type: none;

    //     li {
    //         display: flex;
    //         a {
    //             color: #465a55;
    //             text-decoration: none;
    //         }
    //     }
    // }

    @media only screen and (max-width:991px) {
    
        .navbar-nav{
           display: flex;
           align-items: center;
           justify-content: center;
        }

        .navbar-nav{
            a{
                font-size: 0.9rem;
            }
        }
    
        
    }

    @media only screen and (max-width:500px) {
        .navbar-brand{
            img{
                width: 12vmin !important;
            }
        }
  
        
    }
}